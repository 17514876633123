import React from 'react';

import './index.css';
import workDots from '../../../img/work-dots.svg';
import dotsRight from '../../../img/dots-hero-right.svg';
import hero from '../../../img/technology-hero@2x.png';

export default () => {
  return (
    <section className="tech-hero">
      <span className="circle" />
      <img
        className="center-dots"
        src={workDots}
        alt="Dots"
      />
      <img
        className="left-dots"
        src={workDots}
        alt="Dots"
      />
      <img
        className="right-dots"
        src={dotsRight}
        alt="Dots"
      />
      <div className="top root-padding">
        <div className="left">
          <h1 className="c-title">Technology</h1> 
          <p className="subtitle">
            We help you find the right technology solution. Then we help you
            build it.
          </p>
        </div>
        <img
          className="right"
          src={hero}
          alt="Hero"
        />
      </div>
      <div className="bottom secondary-padding">
        <p>
          Today, every organization is a technology organization. Through data, 
          applications, machine learning, and other custom software solutions, 
          you have the ability to connect with customers, create new products, 
          reduce operational expenses, and improve team collaboration (to name a few).
          <br />
          <br />
          There are a lot of options out there, and we can help you navigate 
          and choose the technology to best achieve your goals. Then we can 
          help you build it.  
          <br />
          <br /> 
          The development team at Devetry builds custom software with an agile 
          approach. Whatever advanced features and functionalities you need are 
          easily created with our team of software developers and business consultants. 
        </p>
      </div>
    </section>
  );
};
