import React from 'react';

import {Link} from 'gatsby';

import { TButton } from '../../Button/';
import './index.css';
import orangeEllipse from '../../../img/orange-ellipse.svg';
import workDots from '../../../img/work-dots.svg';
import phone from '../../../img/iphone-with-circles@2x.png';

export default () => {
  return (
    <section className="tech-custom ">
      <div className="top secondary-padding">
        <img
          className="orange-ellipse"
          src={orangeEllipse}
          alt="orange-ellipse"
        />
        <img
          className="first-dots"
          src={workDots}
          alt="Dots"
        />
        <img
          className="second-dots"
          src={workDots}
          alt="Dots"
        />
        <div className="red-dot" />
        <div className="blog-left">
          <p className="c-title">Custom Software Development</p>
          <p className="subtitle">
            We started as a custom software development company in Denver, and
            while we’ve expanded to other technology and services, it’s still
            our bread and butter. <br />
            <br /> We love hearing about your ideas, problems, and roadblocks
            and finding solutions that address your current and future needs.
            Our experience building software products across countless
            industries allows us to provide innovative solutions and maximize
            ROI. Whether you need a minimum viable product or a full rewrite of
            your legacy software application, we’d be delighted to find a
            solution that works within your budget, time constraints, and
            business standards.
          </p>
          <div className='margin-left-50'>
            <Link to='/services/technology/software-development'>
              <TButton
                className="redirect-link first"
                text="Learn More"
              />
            </Link>
          </div>
          <p className="c-title">Web & Mobile App Development</p>
          <p className="subtitle">
            The average business has hundreds of applications with new apps
            being developed and deployed each month. Whether you need an
            internal application to boost productivity or an application to
            control a single device, we can help. Our team of software
            developers creates native, hybrid, and web apps using a variety of
            platforms and frameworks.
          </p>
          <div className='margin-left-50'>
            <Link to='/services/technology/web-mobile-app-development'>
              <TButton 
                className='redirect-link'
                text="Learn More" 
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="center secondary-padding">
        <img
          className="center-dots"
          src={workDots}
          alt="Dots"
        />
        <img
          className="third-dots"
          src={workDots}
          alt="Dots"
        />
        <div className="blog-left">
          <p className="c-title">AI and Machine Learning</p>
          <p className="subtitle">
            Take control of your data with custom machine learning systems and
            the latest in artificial intelligence. <br />
            <br /> Our experience in building algorithms helps us create custom
            solutions for advanced technology systems. Whether you need to
            analyze data or steer an autonomous car, our team’s experience in AI
            and machine learning can help your business get ahead.
          </p>
          <div className='margin-left-50'>
            <Link to='/services/technology/ai-machine-learning'>
              <TButton
                className="redirect-link first"
                text="Learn More"
              />
            </Link>
          </div>
          <p className="c-title">Salesforce Development</p>
          <p className="subtitle">
            Salesforce is the world’s leading customer relationship management
            system. While its robust features are often adequate, you may need a
            custom Salesforce application to analyze data or automate processes.
            The team at Devetry has years of experience building and managing
            Salesforce apps and we can help you identify opportunities and build
            helpful tools within the constraints of Salesforce.
          </p>
          <div className='margin-left-50'>
            <Link to='/services/technology/salesforce-dev'>
              <TButton 
                className="redirect-link first"
                text="Learn More"
              />
            </Link>
          </div>
          <p className="c-title">IoT & Embedded Development</p>
          <p className="subtitle">
            The internet of things is an ever-growing, changing ecosystem with
            billions of connected devices across the world. The devices
            connected to this ecosystem require elevated elements of security
            and reliability. Our development team can help you embed software
            across your devices to enhance your product and collect data.
            Embedded development, prototyping, and testing with continuous
            integration are all methods the Devetry team utilizes in IoT
            development.
          </p>
          <div className='margin-left-50'>
            <Link to='/services/technology/iot-embedded/'>
              <TButton 
                className='redirect-link'
                text="Learn More"
              />
            </Link>
          </div>
        </div>
      </div>
      <div className="bottom secondary-padding">
        <div className="blog-left">
          <p className="c-title">System Integration</p>
          <p className="subtitle">
            We can analyze your in-use systems and how they serve various
            business functions in your organization. Then, working with
            stakeholders, we identify opportunities to create efficiencies,
            determine custom and third-party solutions, configure systems for
            ease of use, and train end-users. <br />
            <br /> We create and integrate APIs to ensure that your platforms
            send data in an efficient, secure manner. Some of the common
            platforms we perform integrations with include CRMS (ex. Salesforce,
            Zoho), finance systems (ex. QuickBooks, Xero), cloud and application
            platforms (ex. AWS), marketing systems (ex. HubSpot CRM), ERP
            systems (ex. NetSuite), payment systems (ex. Stripe), and insurance
            clearinghouses (ex. CHC).
          </p>


          <p className="c-title">Data Analytics and Visualization</p>
          <p className="subtitle">
            Data is only as good as the visualization and analysis that comes
            from that data. If you’re an organization who uses data to fuel your
            strategy, you need to be able to decipher, share, and annotate data
            or anomalies. The team at Devetry can build real-time and historic
            charts, graphs, and comprehensive dashboards for your internal and
            external software products.
          </p>
        </div>
        <div className="right">
          <img
            src={phone}
            alt="Phone"
          />
        </div>
        <img
          className="top-dots"
          src={workDots}
          alt="Dots"
        />
        <img
          className="bottom-dots"
          src={workDots}
          alt="Dots"
        />
      </div>
    </section>
  );
};
