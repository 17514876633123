import React, { useEffect, useContext } from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import { Helmet } from 'react-helmet';

import BestBlogs from '../../components/BestBlogs';
import CTABanner from '../../components/CTABanner';
import Slider from '../../components/Slider';
import AppContext from '../../contexts/AppContext';
import './index.css';

// Sections
import Hero from '../../components/technologySections/Hero';
import Custom from '../../components/technologySections/Custom';
import Red from '../../components/technologySections/Red';

const pageTitle =
  'Software Development Services | Software Developers | Devetry';
const pageDescription =
  'Software development services made simple. Our software developers can build in any tech stack. Contact our team to learn more!';

const TechnologyPageTemplate = ({ blogs, sliderImages }) => {
  const { setActivePage } = useContext(AppContext);

  useEffect(() => {
    setActivePage('services');
  }, [setActivePage]);

  return (
    <>
      <Helmet>
        <title>{pageTitle}</title>
        <meta name="description" content={pageDescription} />
        <meta property="og:title" content={pageTitle} />
      </Helmet>
      <div className="root-container tech">
        <Hero />
        <Custom />
        <Red
          title="We don’t choose favorites"
          body=" We believe that solving business problems comes first and tech stacks
      come second. That’s why our team works in a variety of languages,
      frameworks, databases, and tools–so we can work with your technology
      immediately and deliver value from day one."
        />
        <section className="technology-slider">
          <Slider images={sliderImages} />
        </section>
        <BestBlogs items={blogs} title="Let's Get Techy" />
        <CTABanner
          text="Let’s build something awesome"
          buttonText="SEE ALL OUR PROJECTS"
          link="/work"
        />
      </div>
    </>
  );
};

const TechnologyPage = ({ data }) => {
  const { frontmatter } = data.markdownRemark;
  const { edges } = data.allMarkdownRemark;
  const techBlogs = edges.map((e) => ({
    readingTime: e.node.fields.readingTime.text,
    slug: e.node.fields.slug,
    ...e.node.frontmatter
  }));

  return (
    <TechnologyPageTemplate
      sliderImages={frontmatter.sliderImages}
      blogs={techBlogs}
    />
  );
};

TechnologyPage.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.shape({
      frontmatter: PropTypes.object
    })
  })
};

export default TechnologyPage;

export const pageQuery = graphql`
  query TechnologyPageTemplate {
    markdownRemark(frontmatter: { templateKey: { eq: "Technology/index" } }) {
      frontmatter {
        templateKey
        sliderImages {
          src {
            childImageSharp {
              fluid(quality: 100) {
                ...GatsbyImageSharpFluid
              }
            }
          }
          alt
        }
      }
    }
    allMarkdownRemark(
      filter: {
        published: { eq: true },
        frontmatter: {
          templateKey: { eq: "Blog/index" }
          category: { eq: "Development" }
        }
      }
      limit: 3
      sort: { fields: frontmatter___date, order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
            readingTime {
              text
            }
          }
          frontmatter {
            title
            date
            category
          }
        }
      }
    }
  }
`;
