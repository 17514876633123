import React from 'react';

import './index.css';
import greenEllipse from '../../../img/green-ellipse.svg';

export default ({ title, body }) => {
  return (
    <section className="tech-red secondary-padding">
      <img src={greenEllipse} alt="Ellipse" />
      <p className="c-title">{title}</p>
      <p className="body">{body}</p>
    </section>
  );
};
